import { connect } from 'react-redux'
import {
  doUpdateTicketSearchQuery,
  doToggleListSearchBoxFocused,
  doSubmitTicketsSearch,
  doClearSearch,
} from 'actions/search'
import { selectCurrentCommittedTicketSearchQueryString } from 'selectors/search/base'
import { selectTicketSearchSuggestions } from 'selectors/search_suggestions'
import { selectCustomersByEmail } from 'ducks/customers/selectors'
import { doSearchRecipients } from 'ducks/drafts2/operations'

import SearchView from 'components/ConversationList/Header/Search'
import { selectCurrentSubmittedTicketSearchFilterMatches } from 'selectors/search/searchFilters'

const select = state => {
  return {
    committedSearchQuery: selectCurrentCommittedTicketSearchQueryString(state),
    customersByEmail: selectCustomersByEmail(state),
    suggestions: selectTicketSearchSuggestions(state),
    currentSubmittedTicketSearchFilterMatches: selectCurrentSubmittedTicketSearchFilterMatches(
      state
    ),
  }
}

const perform = {
  doToggleListSearchBoxFocused,
  doUpdateTicketSearchQuery,
  doSearchRecipients,
  doSubmitTicketsSearch,
  doClearSearch,
}

export default connect(select, perform)(SearchView)
