import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { selectFolderCollectionsByType } from 'ducks/folders/selectors/collections'
import {
  doBuildInboxMenuFromMailboxes,
  doRedirectToCollectionAndFolderById,
} from 'ducks/folders/operations/collections'
import FolderCollection from 'components/Folders/Collection'
import { selectIsOnTicketsPage } from 'selectors/location'
import {
  selectIsOnMainPage,
  selectIsOnSearchPageType,
} from 'ducks/location/selectors'

export default function Folders({ type }) {
  const dispatch = useDispatch()
  const [hasTriggeredBootstrap, setHasTriggeredBootstrap] = useState(false)
  const collections = useSelector(state =>
    selectFolderCollectionsByType(state, type)
  )
  const isBootstrapped = useSelector(state =>
    selectIsBootstrapTypedInStates(
      state,
      ['folders', 'mailboxes', 'currentUser', 'agents', 'labels'],
      ['LOADED']
    )
  )
  const isOnSearch = useSelector(selectIsOnSearchPageType)
  const isOnTickets = useSelector(selectIsOnTicketsPage)
  const isOnMain = useSelector(selectIsOnMainPage)
  const autoRedirect = isOnSearch || isOnTickets || isOnMain

  const onBootstrap = useCallback(
    () => {
      dispatch(
        doBuildInboxMenuFromMailboxes({ autoRedirect, ignoreLast: false })
      )
    },
    [dispatch, autoRedirect]
  )

  const onMainPage = useCallback(
    () => {
      dispatch(
        doRedirectToCollectionAndFolderById(null, null, { ignoreLast: false })
      )
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (onBootstrap && isBootstrapped) {
        if (!hasTriggeredBootstrap) {
          onBootstrap()
          setHasTriggeredBootstrap(true)
        } else if (isOnMain) {
          onMainPage()
        }
      }
    },
    [
      onBootstrap,
      onMainPage,
      isOnMain,
      isBootstrapped,
      hasTriggeredBootstrap,
      setHasTriggeredBootstrap,
    ]
  )

  const compactView = collections.length === 1

  return (
    <>
      {collections.map(({ id }) => (
        <FolderCollection
          key={id}
          collectionId={id}
          compactView={compactView}
        />
      ))}
    </>
  )
}
