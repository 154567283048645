import { connect } from 'react-redux'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import {
  doToggleTicketSelection,
  doRangedTicketSelection,
  doListTopChanged,
} from 'actions/app'
import { doNavigateToTicket } from 'actions/navigation'
import { doMarkBulkSelectionMode } from 'actions/tickets'
import { selectQueryParams } from 'selectors/location'
import {
  selectSearchIsLoadedByQueryId,
  selectSearchIsLoadingMoreByQueryId,
  selectSearchHasMoreByQueryId,
  selectSearchEntityIdsByQueryId,
  selectSearchFilterByQueryId,
  selectSearchIsErrorByQueryId,
  selectEntityCountByQueryId,
  selectIsRefreshingSearches,
} from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'

import ConversationList from 'components/ConversationList'
import { doFetchTickets } from 'ducks/tickets/actions'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doClose } from 'ducks/tickets/actions/doClose'
import { doOpen } from 'ducks/tickets/actions/doOpen'
import { snow } from 'util/ui/confetti'
import TicketListItem from './ListItem'
import TicketEmptyState from './EmptyState'

let firstLoad = true

const select = state => {
  const queryId = selectCurrentQueryId(state)
  return {
    currentQueryId: queryId,
    hasLoaded: selectSearchIsLoadedByQueryId(state, queryId),
    canLoadMore: selectSearchHasMoreByQueryId(state, queryId),
    isLoadingMore: selectSearchIsLoadingMoreByQueryId(state, queryId),
    isRefreshing: selectIsRefreshingSearches(state),
    isSelectionMode: selectIsTicketSelectionMode(state),
    selectedConversationIds: selectSelectedTicketIds(state),
    conversationIds: selectSearchEntityIdsByQueryId(state, queryId),
    currentConversationId: selectCurrentTicketId(state),
    queryFilter: selectSearchFilterByQueryId(state, queryId),
    ListItem: TicketListItem,
    EmptyState: TicketEmptyState,
    hasErrored: selectSearchIsErrorByQueryId(state, queryId),
    totalCount: selectEntityCountByQueryId(state, queryId),
    is3ColumnView: selectPrefersClassicView(state),
  }
}

const onListLoaded = () => {
  return (_dispatch, getState) => {
    const { created } = selectQueryParams(getState())
    if (firstLoad && created === 'inbox') {
      snow({ duration: 1 })
    }
    firstLoad = false
  }
}

const onComponentDidMount = () => {
  return () => {
    firstLoad = true
  }
}

const perform = {
  fetchFolderCounts: doFetchFolderCounts,
  loadMore: doFetchTickets,
  markBulkSelectionMode: doMarkBulkSelectionMode,
  openConversationPage: doNavigateToTicket,
  toggleConversationSelection: doToggleTicketSelection,
  onRangedSelection: doRangedTicketSelection,
  onTopChanged: doListTopChanged,
  closeConversation: doClose,
  openConversation: doOpen,
  navigateToConversation: doNavigateToTicket,
  onListLoaded,
  onComponentDidMount,
}

export default connect(select, perform)(ConversationList)
