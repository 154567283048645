import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectMergingMergeTicketId,
  selectMergingMergeTicketTitle,
  selectMergingIsMerging,
  selectMergingMergeDirection,
} from 'ducks/merging/selectors/base'
import {
  doResetToDefaults,
  doChangeMergeDirection,
  doFetchMergeSearchByQueryId,
} from 'ducks/merging/operations'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import {
  ArrowDown,
  ArrowUpDown,
} from '@groovehq/internal-design-system/lib/assets/icons'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectMergingSearchQueryId } from 'ducks/merging/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectDefaultFolderItemQueryId } from 'ducks/folders/selectors/items/selectDefaultFolderItemQueryId'
import { selectCurrentContact } from 'ducks/crm/contacts'
import { emptyObj } from 'util/objects'
import { doMerge } from 'ducks/tickets/actions/doMerge'
import { doOpenTicketPage } from 'actions/pages'

import SelectedTicket from './SelectedTicket'
import TicketSelector from './TicketSelector'
import { styles } from './styles'

const TicketMergeDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const mergeQueryId = useSelector(selectMergingSearchQueryId)
  const currentQueryId = useSelector(selectCurrentQueryId)
  const defaultQueryId = useSelector(selectDefaultFolderItemQueryId)
  const { email: contactEmail } = useSelector(selectCurrentContact) || emptyObj
  const currentTicketId = useSelector(selectCurrentTicketId)
  const currentTicket = useSelector(selectCurrentConversation)
  const mergeTicketId = useSelector(selectMergingMergeTicketId)
  const mergeTicketTitle = useSelector(selectMergingMergeTicketTitle)
  const isMerging = useSelector(selectMergingIsMerging)
  const direction = useSelector(selectMergingMergeDirection)

  const onMerge = useCallback(
    async () => {
      const params = {}

      if (direction === 'normal') {
        params.targetTicketId = currentTicket.id
        params.sourceTicketId = mergeTicketId
      } else {
        params.targetTicketId = mergeTicketId
        params.sourceTicketId = currentTicket.id
      }

      await dispatch(doMerge(params.targetTicketId, params.sourceTicketId))

      dispatch(doOpenTicketPage(params.targetTicketId))
    },
    [dispatch, direction, currentTicket, mergeTicketId]
  )

  const onReverse = useCallback(
    () =>
      dispatch(
        doChangeMergeDirection(direction === 'normal' ? 'swapped' : 'normal')
      ),
    [dispatch, direction]
  )

  const topSelector = useMemo(
    () => {
      if (direction === 'normal') {
        return <TicketSelector />
      }

      return <SelectedTicket ticketId={currentTicketId} />
    },
    [direction, currentTicketId]
  )

  const bottomSelector = useMemo(
    () => {
      if (direction === 'normal') {
        return <SelectedTicket ticketId={currentTicketId} />
      }

      return <TicketSelector />
    },
    [direction, currentTicketId]
  )

  useEffect(
    () => {
      if (!mergeQueryId) {
        const newQueryId = contactEmail
          ? `from:${contactEmail}`
          : currentQueryId || defaultQueryId
        dispatch(doFetchMergeSearchByQueryId(newQueryId))
      }
    },
    [dispatch, mergeQueryId, currentQueryId, defaultQueryId, contactEmail]
  )

  useEffect(
    () => {
      return () => {
        dispatch(doResetToDefaults())
      }
    },
    [dispatch]
  )

  if (!currentTicket) {
    return null
  }

  return (
    <div>
      <Drawer
        open={open}
        title="Merging..."
        onClose={onClose}
        footer={
          <ModalBtns
            saveBtnText={isMerging ? 'Merging...' : 'Merge'}
            saveBtnDisabled={!mergeTicketId || isMerging}
            saveBtnTestId="ticket-merge-button"
            onSave={onMerge}
            tertiaryBtnText="Cancel"
            onClickTertiaryBtn={onClose}
          />
        }
        data-test-id="ticket-merge-drawer"
      >
        <div className="grui flex flex-col h-100 pt-12">
          {topSelector}

          <div
            css={styles.label}
            className="grui flex justify-center items-center mt-10 mb-10"
          >
            <div css={styles.arrowDown}>
              <ArrowDown />
            </div>

            <Tooltip title="Change direction" position="left" strategy="fixed">
              <Button
                type="icon"
                size="small"
                css={styles.switchButton}
                onClick={onReverse}
                ti
              >
                <ArrowUpDown size="small" />
              </Button>
            </Tooltip>
          </div>

          {bottomSelector}

          {mergeTicketId &&
            (direction === 'normal' ? (
              <p className="grui mt-10" css={styles.noticeMessage}>
                The conversation <b>{mergeTicketTitle}</b> (#{mergeTicketId})
                will be deleted and appear at the bottom of the conversation{' '}
                <b>{currentTicket.title}</b> (#{currentTicket.id}).
              </p>
            ) : (
              <p className="grui mt-10" css={styles.noticeMessage}>
                The conversation <b>{currentTicket.title}</b> (#{
                  currentTicket.id
                }) will be deleted and appear at the bottom of the conversation{' '}
                <b>{mergeTicketTitle}</b> (#{mergeTicketId}).
              </p>
            ))}

          <p className="grui mt-auto mb-0 text-center">
            <b>You cannot undo this action.</b> Merging long conversations may
            take a few seconds.
          </p>
        </div>
      </Drawer>
    </div>
  )
}

export default TicketMergeDrawer
