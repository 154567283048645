import React, { useEffect, useState } from 'react'
import { isBlank } from 'util/strings'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import DemoInboxCard from 'components/TrialSetup/DemoInboxCard'
import OnboardingWorkflowCard from 'components/TrialSetup/OnboardingWorkflowCard'
import TicketConversationList from 'subapps/ticketing/components/TicketList/TicketConversationList'
import TicketListHeader from 'subapps/ticketing/components/TicketList/Header'
import { selectShowDemoInboxCard } from 'selectors/trial_setup'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectShowOnboardingWorkflowInConversationList } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflowInConversationList'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import usePrevious from 'util/hooks/usePrevious'
import { doFetchTickets } from 'ducks/tickets/actions'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import {
  selectIsOnSearchPageType,
  selectIsOnSearchPageWithLegacySearch,
} from 'ducks/location/selectors'

import styles from './styles.less'
import LimitSearchHistoryCard from '../LimitSearchHistoryCard'

// Wraps the ticket list container and adds mouseEnter/Leave events
const TicketList = ({ channelType }) => {
  const dispatch = useDispatch()
  const [hasLoadedCounts, setHasLoadedCounts] = useState(false)
  const currentQueryId = useSelector(selectCurrentQueryId)
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const prevCurrentQueryId = usePrevious(currentQueryId)
  const { channel = [] } = useSelector(selectCurrentQuery)
  const channelId = channel[0]
  const prevChannelId = usePrevious(channelId)
  const isOnSearchPage = useSelector(selectIsOnSearchPageType)

  const showDemoInboxCard = useSelector(selectShowDemoInboxCard)
  const showOnboardingWorkflow = useSelector(
    selectShowOnboardingWorkflowInConversationList
  )
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const isOnSearchPageWithLegacySearch = useSelector(
    selectIsOnSearchPageWithLegacySearch
  )
  useEffect(
    () => {
      if (!isBlank(currentQueryId) && currentQueryId !== prevCurrentQueryId) {
        dispatch(doFetchTickets({ isReload: false, loadFirst: true }))
      } else if (
        isBlank(currentQueryId) &&
        isOnSearchPage &&
        // We have a directy implemented in the doBuildInboxMenuFromMailboxes which will
        // convert and redirect to valid search url. The problem is that this legacy search
        // doesnt cause the currentQueryId to be set. We need to wait for the redirection code
        // to do its thing and after that we should redirect away if the query id is blank.
        !isOnSearchPageWithLegacySearch
      ) {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast: true,
          })
        )
      }

      // We always need  to load the global non channel search
      // If the request below wont load it, do it here
      if (!hasLoadedCounts && channelId) {
        dispatch(doFetchFolderCounts({ channelType }))
      }

      if (!hasLoadedCounts || prevChannelId !== channelId) {
        if (!hasLoadedCounts) setHasLoadedCounts(true)
        dispatch(doFetchFolderCounts({ channelId, channelType }))
      }
    },
    [
      dispatch,
      currentQueryId,
      prevCurrentQueryId,
      prevChannelId,
      channelId,
      channelType,
      hasLoadedCounts,
      isOnSearchPage,
      isOnSearchPageWithLegacySearch,
    ]
  )

  return (
    <div className={styles.leftCell}>
      <TicketListHeader isSidebarOpen isWide={is3ColumnView} />
      {isManager && (
        <>
          <LimitSearchHistoryCard
            className={cn('grui mx-10 my-5', {
              'pt-10': !is3ColumnView,
            })}
          />
          {showOnboardingWorkflow && (
            <OnboardingWorkflowCard
              mini={!is3ColumnView}
              className={cn({
                'grui mb-14 mt-14': is3ColumnView,
              })}
            />
          )}
        </>
      )}
      <TicketConversationList channelType={channelType} />
      {!showOnboardingWorkflow &&
        showDemoInboxCard && <DemoInboxCard mini={!is3ColumnView} />}
    </div>
  )
}

export default TicketList
