import { connect } from 'react-redux'

import { doOpenForwardPage } from 'actions/pages'
import { doPrintWholeTicket, doOpenTicketMergeDrawer } from 'actions/tickets'
import { doMarkAsRead } from 'ducks/tickets/actions/doMarkAsRead'
import { doUnread } from 'ducks/tickets/actions/doUnread'

import { selectPossibleMailboxesForChange } from 'ducks/mailboxes/selectors/selectPossibleMailboxesForChange'
import { DELETE_MODE_HARD, DELETE_MODE_SOFT } from 'ducks/tickets/constants'
import { doTrash } from 'ducks/tickets/actions/doTrash'
import { doDelete } from 'ducks/tickets/actions/doDelete'
import { doRestore } from 'ducks/tickets/actions/doRestore'
import { doToggleSpam } from 'ducks/tickets/actions/doToggleSpam'
import { doFollow } from 'ducks/tickets/actions/doFollow'
import { doUnfollow } from 'ducks/tickets/actions/doUnfollow'
import { selectCurrentConversationIsDeleted } from 'ducks/tickets/selectors/selectCurrentConversationIsDeleted'
import { selectCurrentConversationIsSpam } from 'ducks/tickets/selectors/selectCurrentConversationIsSpam'
import { selectCurrentConversationIsUnread } from 'ducks/tickets/selectors/selectCurrentConversationIsUnread'
import { selectCurrentConversationIsFollowing } from 'ducks/tickets/selectors/selectCurrentConversationIsFollowing'
import { doUpdateChannel } from 'ducks/channels/actions'

import MainDropdown from './view'

const select = state => {
  return {
    isDeleted: selectCurrentConversationIsDeleted(state),
    isSpam: selectCurrentConversationIsSpam(state),
    isUnread: selectCurrentConversationIsUnread(state),
    isFollowing: selectCurrentConversationIsFollowing(state),
    mailboxes: selectPossibleMailboxesForChange(state),
  }
}

const perform = dispatch => ({
  doToggleTicketAsSpam: ticketId => dispatch(doToggleSpam(ticketId)),
  doMarkAsRead: ticketId => dispatch(doMarkAsRead(ticketId)),
  doMarkAsUnread: ticketId => dispatch(doUnread(ticketId)),
  doChangeMailbox: (ticketId, mailboxId) =>
    dispatch(doUpdateChannel(ticketId, mailboxId)),
  doDeleteTicket: (ticketId, { deleteMode = DELETE_MODE_SOFT } = {}) => {
    if (deleteMode === DELETE_MODE_HARD) {
      return dispatch(doDelete(ticketId))
    }
    return dispatch(doTrash(ticketId))
  },

  doRestoreTicket: ticketId => dispatch(doRestore(ticketId)),
  doFollowTicket: ticketId => dispatch(doFollow(ticketId)),
  doUnfollowTicket: ticketId => dispatch(doUnfollow(ticketId)),
  doOpenForwardTicketPage: ticketId => {
    return dispatch(doOpenForwardPage(ticketId))
  },
  doPrintWholeTicket: ticketId => {
    return dispatch(doPrintWholeTicket(ticketId))
  },
  doOpenTicketMergeDrawer: ticketId => {
    return dispatch(doOpenTicketMergeDrawer(ticketId))
  },
})

export default connect(select, perform)(MainDropdown)
